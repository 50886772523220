// src/App.js
import React from "react";
import AcessoZap from "./components/AcessoZap";
import "./components/blockDevTools";

function App() {
  return (
    <div className="container">
      <div className="content">
        <div id="acesso">
          <AcessoZap />
        </div>
      </div>
    </div>
  );
}

export default App;
