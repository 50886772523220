import Swal from "sweetalert2";

function blockDevTools() {
  const redirectTo = "https://acessorestrito.fsdeafs.com.br";

  const isMobile = /Mobi|Android|iPhone|iPad|iPod/.test(navigator.userAgent);

  if (isMobile) {
    return;
  }

  const devToolsDetector = setInterval(() => {
    const threshold = 160;
    const widthThreshold = window.outerWidth - window.innerWidth > threshold;
    const heightThreshold = window.outerHeight - window.innerHeight > threshold;

    if (widthThreshold || heightThreshold) {
      clearInterval(devToolsDetector);
      Swal.fire({
        icon: "error",
        title: "Acesso Restrito",
        text: "O acesso ao Dev Tools é restrito. Por favor, não tente acessar as ferramentas de desenvolvedor.",
      }).then(() => {
        window.location.href = redirectTo;
      });
    }
  }, 1000);

  const blockKeyCombination = (event) => {
    const blockedKeys = [
      { key: "F12" },
      { key: "I", ctrlKey: true, shiftKey: true },
      { key: "J", ctrlKey: true, shiftKey: true },
      { key: "C", ctrlKey: true, shiftKey: true },
      { key: "U", ctrlKey: true },
      { key: "S", ctrlKey: true, shiftKey: true },
    ];

    blockedKeys.forEach((blockedKey) => {
      const keyMatches = event.key.toUpperCase() === blockedKey.key;
      const ctrlMatches = event.ctrlKey === !!blockedKey.ctrlKey;
      const shiftMatches = event.shiftKey === !!blockedKey.shiftKey;

      if (keyMatches && ctrlMatches && shiftMatches) {
        event.preventDefault();
        Swal.fire({
          icon: "error",
          title: "Acesso Restrito",
          text: "O acesso ao Dev Tools é restrito. Por favor, não tente acessar as ferramentas de desenvolvedor.",
        }).then(() => {
          window.location.href = redirectTo;
        });
      }
    });
  };

  const blockContextMenu = (event) => {
    event.preventDefault();
    Swal.fire({
      icon: "error",
      title: "Acesso Restrito",
      text: "O acesso ao Dev Tools é restrito. Por favor, não tente acessar as ferramentas de desenvolvedor.",
    }).then(() => {
      window.location.href = redirectTo;
    });
  };

  const blockViewSource = () => {
    if (window.location.href.startsWith("view-source:")) {
      window.location.href = redirectTo;
    }
  };

  window.addEventListener("keydown", blockKeyCombination);
  window.addEventListener("contextmenu", blockContextMenu);
  blockViewSource();
}

if (!/Mobi|Android|iPhone|iPad|iPod/.test(navigator.userAgent)) {
  blockDevTools();
}
